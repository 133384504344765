<template>
  <!-- 员工照片采集页 -->
  <div class="faceCollection">
    <van-nav-bar
      :title="'员工照片采集'"
      left-text
      left-arrow
      @click-left="onClickLeft"
    >
    </van-nav-bar>
    <div class="overflow_div">
      <div class="user_box">
        <div class="user1">
          <span>工号：</span>
          <span>{{ userData.empcode }}</span>
        </div>
        <div class="user2">
          <span>姓名：</span>
          <span>{{ userData.empname }}</span>
        </div>
        <div class="user3">
          <span>部门：</span>
          <span>{{ userData.deptname }}</span>
        </div>
      </div>
      <div class="images_box">
        <div v-if="loading1" class="loading">
          <van-loading type="spinner" color="#1989fa" />
        </div>
        <van-image v-else fit="contain" :src="pic1" />
        <template v-if="use_cappic2">
          <div v-if="loading2" class="loading">
            <van-loading type="spinner" color="#1989fa" />
          </div>
          <van-image v-else fit="contain" :src="pic2" />
        </template>
      </div>
      <div class="upload_box">
        <van-uploader accept="image/*" :max-count="1" :after-read="afterRead1">
          <van-button
            size="normal"
            :icon="require('@assets/img/face_pic.png')"
            type="info"
            >拍照1</van-button
          >
        </van-uploader>
        <van-uploader
          v-if="use_cappic2"
          accept="image/*"
          :max-count="1"
          :after-read="afterRead2"
        >
          <van-button
            size="normal"
            :icon="require('@assets/img/face_pic.png')"
            type="info"
            >拍照2</van-button
          >
        </van-uploader>
      </div>
      <div class="description">
        <van-image fit="contain" :src="require('@assets/img/face_dis.png')" />
      </div>
    </div>
  </div>
</template>

<script>
import { Toast, Dialog } from 'vant'
import Compressor from 'compressorjs'
import { getEmpInfoByEmpCode, upLoadFile, getCapPicPath } from '@api/wx.js'
import { getDownLoadFileName } from '@api/wxzp.js'
import { checkUserLogin } from '@api/login.js'
import { getOpenId } from '@/utils/auth'
export default {
  data() {
    const userInfo = localStorage.userInfo
      ? JSON.parse(localStorage.userInfo)
      : {}
    return {
      userInfo,
      pic1: require('@assets/img/login.png'),
      pic2: require('@assets/img/login.png'),
      userData: {},
      use_cappic2: window.use_cappic2 || 0,
      loading1: false,
      loading2: false,
      CapPicpath: 'CapPic'
    }
  },
  created() {
    this._getCapPicPath()
    // 触发一次登陆，保证单点登陆也有openid
    this.checkUserLogin()
  },
  methods: {
    checkUserLogin() {
      let hasOpenId = getOpenId()
      if (hasOpenId === '0') hasOpenId = ''
      checkUserLogin({
        username: this.userInfo.username,
        pwd: localStorage.getItem('v8_login_pwd'),
        langid: localStorage.getItem('langid') || 0,
        openid: hasOpenId || ''
      }).then(res => {})
    },
    _getCapPicPath() {
      getCapPicPath()
        .then(res => {
          this.CapPicpath = res
        })
        .finally(() => {
          // 获取用户信息
          this.getEmpInfoByEmpCodeData()
          this.getDownLoadFileNameData(5)
          this.getDownLoadFileNameData(6)
        })
    },
    onClickLeft() {
      // this.$router.back()
      if (this.$route.query.from == 'home') {
        this.$router.push('/home')
      } else {
        this.$router.push('/personCen')
      }
    },
    // 获取自拍照1和自拍照2的地址
    getDownLoadFileNameData(dflag) {
      getDownLoadFileName({
        dflag: dflag,
        moduleno: 201,
        autoid: this.userInfo.empid,
        filename: 'CapPic',
        fileext: '.jpg',
        downloadpath: this.CapPicpath
      }).then(res => {
        if (dflag == 5) {
          this.pic1 = res + '?v=' + Date.parse(new Date())
        } else {
          this.pic2 = res + '?v=' + Date.parse(new Date()) + 60
        }
      })
    },
    // 获取用户信息
    getEmpInfoByEmpCodeData() {
      getEmpInfoByEmpCode({
        empcode: this.userInfo.empcode
      }).then(res => {
        this.userData = res.data[0]
      })
    },
    // 拍照1上传
    async afterRead1(file) {
      this.loading1 = true
      const base64s = file.content
      let realFile = file.file
      let quality = 0.6
      if (
        realFile.size >= 1024 * 1024 * 0.5 &&
        1024 * 1024 * 1 > realFile.size
      ) {
        quality = 0.8
      }
      if (realFile.size >= 1024 * 1024 * 1 && 1024 * 1024 * 2 > realFile.size) {
        quality = 0.6
      }
      if (realFile.size >= 1024 * 1024 * 2 && 1024 * 1024 * 5 > realFile.size) {
        quality = 0.4
      }
      if (realFile.size >= 1024 * 1024 * 5) {
        quality = 0.2
      }
      let f = name || realFile.name
      const ext = f.substring(f.lastIndexOf('.') + 1)
      if ('png|jpg|jpeg|emp'.includes(ext)) {
        // 如果是图片，则先压缩，防止提交失败
        realFile = await this.compressorImage(realFile, 'file', quality)
      }
      let newFile = null
      newFile = await this.reFileName('image/jpg', realFile, 'CapPic.jpg')
      let params = {
        file: newFile,
        upflag: 5,
        moduleno: 201,
        autoid: this.userInfo.empid,
        uploadpath: this.CapPicpath,
        username: this.userInfo.username,
        packagetype: ''
      }
      upLoadFile(params).then(res => {
        this.loading1 = false
        if (res == 'OK') {
          this.pic1 = base64s
          Toast.success('上传成功!')
        } else {
          Dialog.alert({
            message: res
          }).then(() => {
            // on close
          })
        }
      })
    },
    // 拍照2上传
    async afterRead2(file) {
      this.loading2 = true
      const base64s = file.content
      let realFile = file.file
      let quality = 0.6
      if (
        realFile.size >= 1024 * 1024 * 0.5 &&
        1024 * 1024 * 1 > realFile.size
      ) {
        quality = 0.8
      }
      if (realFile.size >= 1024 * 1024 * 1 && 1024 * 1024 * 2 > realFile.size) {
        quality = 0.6
      }
      if (realFile.size >= 1024 * 1024 * 2 && 1024 * 1024 * 5 > realFile.size) {
        quality = 0.4
      }
      if (realFile.size >= 1024 * 1024 * 5) {
        quality = 0.2
      }
      let f = name || realFile.name
      const ext = f.substring(f.lastIndexOf('.') + 1)
      if ('png|jpg|jpeg|emp'.includes(ext)) {
        // 如果是图片，则先压缩，防止提交失败
        realFile = await this.compressorImage(realFile, 'file', quality)
      }
      let newFile = null
      newFile = await this.reFileName('image/jpg', realFile, 'CapPic.jpg')
      let params = {
        file: newFile,
        upflag: 6,
        moduleno: 201,
        autoid: this.userInfo.empid,
        uploadpath: this.CapPicpath,
        username: this.userInfo.username,
        packagetype: ''
      }
      upLoadFile(params).then(res => {
        this.loading2 = false
        if (res == 'OK') {
          this.pic2 = base64s
          Toast.success('上传成功!')
        } else {
          Dialog.alert({
            message: res
          }).then(() => {
            // on close
          })
        }
      })
    },
    // 压缩图片
    compressorImage(image, backType, quality) {
      return new Promise((resolve, reject) => {
        new Compressor(image, {
          quality: quality || 0.8,
          success(result) {
            let lastIndexOf = image.name.lastIndexOf('.')
            let name = image.name.substr(0, lastIndexOf) + '.png'
            // console.log(image, 'image.type', name);
            let file = new File([result], name, { type: 'image/png' })
            // console.log(file, 'filefilefile');
            if (!backType || backType == 'blob') {
              resolve(result)
            } else if (backType == 'file') {
              resolve(file)
            } else {
              resolve(file)
            }
          },
          error(err) {
            console.log('图片压缩失败---->>>>>', err)
            reject(err)
          }
        })
      })
    },
    // 重命名file
    reFileName(type, file, name) {
      const tempFile = file
      const size = tempFile.size
      let uploadFile = new File([tempFile], name, { type, size })
      return uploadFile
      //调用文件上传接口上传文件
    }
  }
}
</script>

<style scoped lang="less">
.faceCollection {
  height: 100vh;
  overflow: hidden;
  // background: #eceaea;
  .van-hairline--bottom::after {
    border-bottom-width: 0;
  }
  .van-nav-bar {
    background: #2b8df0;
    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
    ::v-deep .van-nav-bar__right {
      .van-nav-bar__text {
        color: #fff;
        font-family: Source Han Sans CN;
      }
    }
  }
  .overflow_div {
    background: #ffffff;
    overflow: auto;
    height: calc(100% - 100px);
    .user_box {
      padding: 10px;
      display: flex;
      align-items: center;
      font-size: 24px;
      background: #2b8df0;
      color: #ffffff;
      .user1,
      .user2 {
        width: 25%;
      }
      .user3 {
        width: 50%;
      }
    }
    .images_box {
      margin: 40px 0;
      display: flex;
      justify-content: space-around;
      .loading {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40%;
      }
      .van-image {
        width: 40%;
      }
    }
    .upload_box {
      display: flex;
      justify-content: space-around;
      .van-uploader {
        width: 40%;
        /deep/.van-uploader__wrapper {
          .van-uploader__input-wrapper {
            width: 100%;
            .van-button {
              width: 100%;
            }
          }
        }
      }
    }
    .description {
      margin: 60px 30px;
    }
  }
}
</style>
